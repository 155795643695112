<template>
  <div>
    <v-menu
      bottom
      min-width="120px"
      max-height="200px"
      rounded
      offset-y
      dark
    >
      <template v-slot:activator="{ on }">
        <v-badge
          bordered
          color="green"
          overlap
          dot
          bottom
          left
          offset-x="53"
          offset-y="13"
        >
          <v-btn
            text
            v-on="on"
          >
            <v-icon color="grey" size="38">
              mdi-account-circle
            </v-icon>
            <div class="text-left ml-1 name-area">
              <span class="d-block text-body-2 font-weight-bold white--text">{{ name }}</span>
              <label class="d-block text-capitalize text-caption grey--text text--lighten-2">Role {{ role }}</label>
            </div>
          </v-btn>
        </v-badge>
      </template>
      <v-list dense>
          <v-list-item class="list-item-custom" link>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5" v-text="'ข้อมูลส่วนตัว'"></v-list-item-title>
          </v-list-item>
          <v-list-item class="list-item-custom" link @click="$refs.confirm.show()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5" v-text="'ออกจากระบบ'"></v-list-item-title>
          </v-list-item>
        </v-list>
    </v-menu>
    <dialog-confirm 
      :title="'ยืนยันการออกจากระบบ'" 
      :description="'คุณต้องการออกจากระบบใช่หรือไม่'" 
      @confirm="logout"
      ref="confirm"/>
  </div>
</template>

<script>
import DialogConfirm from '../components/DialogConfirm.vue';

export default {
  props: {
    role: { type: String, default: '' },
    name: { type: String, default: '' }
  },
  data: () => ({

  }),
  methods: {
    logout(){
      localStorage.clear();
      window.location = '';
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm
  }
};
</script>

<style scoped>
  .text-center{
    text-align: center;
  }
  .list-item-custom{
    min-height: 32px !important;
  }
  .name-area{
    max-width: 160px;
    text-align: right;
    text-overflow: ellipsis;
  }
</style>