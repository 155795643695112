import axios from 'axios';
import Text from '../utils/text-mapping';

const formatUser = (user) => {
    if (!user) {
        return {};
    }
    let status = user.status;
    if ('not_verify' == status) {
        status = 'รอยืนยันตัวตน';
    } else if ('active' == status) {
        status = 'กำลังใช้งาน';
    } else if ('suspended' == status) {
        status = 'ระงับการใช้งาน';
    }
    const permissionText = user.permissions?.length > 0 ? user.permissions.join(',') : '';
    return user ? {
        id: user.customerId,
        name: user.name,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
        role: user.role,
        status: status,
        statusCode: user.status,
        permissions: user.permissions,
        permissionText: permissionText,
        mobile: user.mobile,
        email: user.email,
        userId: user.id,
        signature: user.signature || null
    } : {};
};

export async function getUsers () {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/users');
    const usersData = response.data.users;
    let users = [];
    for (let user of usersData) {
        users.push(formatUser(user));
    }
    return { users: users, total: response.data.total };
}

export async function getUserById (id) {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + '/users/' + id);
    const user = response.data.user;
    return formatUser(user);
}

export function getProfile () {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL + '/users/profile')
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
}

export function getPermissionText (permission) {
    return Text.channelText(permission);
}