<template>
  <v-app>
    <Appbar/>
    <v-main class="grey lighten-3">
      <div class="template-custom">
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Appbar from '../components/Appbar.vue';
  export default {
    data: () => ({
      links: [
        'Dashboard',
        'Messages',
        'Profile',
        'Updates'
      ]
    }),
    components: {
        Appbar
    }
  };
</script>

<style scoped>
  .template-custom{
    background-color: #191B20 !important;
    min-height: 100%;
  }
</style>