<template>
    <v-app-bar
      app
      flat
      class="appbar-template"
    >
    <v-container class="py-0 fill-height">
        <v-responsive max-width="100">
          <v-img
          class="my-4 logo"
          lazy-src="https://picsum.photos/id/11/10/6"
          max-height="64"
          max-width="100"
          src="@/assets/images/logo1.svg"
          @click="$router.push({name: 'Home'}).catch(() =>{})"
          ></v-img>
        </v-responsive>
      
        <v-spacer></v-spacer>
        <v-btn
          v-for="link in links"
          :key="link.routeName"
          text
          @click="$router.push({name: link.routeName}).catch(() =>{})"
          :color="link.routeName == $route.matched[0].name ? 'yellow darken-3' : 'grey lighten-2'"
        >
          <v-icon v-if="link.icon">
            {{ link.icon }}
          </v-icon>
          {{ link.name }}
        </v-btn>
        <v-divider dark class="mr-3 ml-1" vertical></v-divider>
        <menu-avatar :role="role" :name="name" />
      </v-container>
    </v-app-bar>
</template>

<script>
import MenuAvatar from '../components/MenuAvatar.vue';
import { getProfile } from '../models/user';
export default {
    data: () => ({
      dataLink: [
        { routeName: 'Users', icon : 'mdi-account-outline', name: 'จัดการผู้ใช้งาน' },
        { routeName: 'Jobs', icon: 'mdi-file-outline', name: 'จัดการคำสั่งซื้อ' },
        { routeName: 'Contents', icon: 'mdi-cog-outline', name: 'จัดการ Content' },
        { routeName: 'Print', icon: 'mdi-printer-outline', name: 'พิมพ์ใบแปะพัสดุ' },
        { routeName: 'Setting', icon: 'mdi-printer-outline', name: 'การตั้งค่า' },
        { routeName: 'Approval', icon: 'mdi-cog-outline', name: 'รายการอนุมัติ' },
        { routeName: 'History', icon: 'mdi-file-outline', name: 'ประวัติการใช้งาน' },
        { routeName: 'Customers', icon: 'mdi-file-outline', name: 'จัดการสมาชิก' }
      ],
      user: null
    }),
    computed: {
      role(){
        let role = this.user ? this.user.role : '';
        if('sr_staff' == role){
          role = 'senior staff';
        }
        return role;
      },
      name(){
        let name =  this.user ? this.user.name : '';
        if(name.length > 20){
          name = name.substring(0, 18) + '..';
        }
        return name;
      },
      links(){
        if(!this.user){
          return [];
        }else{
          const role = this.user.role;
          switch(role){
            case 'staff':
              return [
                this.dataLink[1],
                this.dataLink[2], // uncomment after finish CMS Feature
                this.dataLink[3]
              ];
            case 'sr_staff':
              return [
                this.dataLink[1],
                this.dataLink[2], // uncomment after finish CMS Feature
                this.dataLink[3]
              ];
            case 'approval':
              return [
                this.dataLink[5]
              ];
            case 'account':
              return [
                this.dataLink[1]
              ];
            case 'admin':
              return [
                this.dataLink[7],
                this.dataLink[0],
                this.dataLink[4],
                this.dataLink[6]
              ];
            default:
              return [];
          }
        }
      }
    },
    components: {
      MenuAvatar
    },
    async mounted(){
      // get profile
      if(this.user != null) return;

      try{
        const response = await getProfile();
        const user = response.data.user;
        this.user = user;
        this.$store.dispatch('user/updateCurrentUser', user);
      }catch(error){
        console.log(error);
      }
    }
};
</script>

<style scoped>
  .appbar-template{
    background-color: #191B20 !important;
    border-bottom: 1px solid #FFFFFF;
  }
</style>

<style>
  .logo:hover{
    cursor: pointer;
  }
</style>